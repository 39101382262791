import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { notification, Result } from "antd";
import React from "react";
import { trackPromise } from "react-promise-tracker";
import { globalAppConfig } from "../../config";
import GlobalStoreContext from "../../GlobalStoreContext";
import FeedbackHelpModal from "../FeedbackHelpModal/feedbackHelpModal";

const theme = createTheme({
    palette: {
        primary: {
            main: "#FF9E00",
        },
        secondary: {
            main: "#d8d8d8",
        },
        background: {
            default: "#ffffff",
        },
    },
    typography: {
        fontFamily: ["Open Sans"].join(","),
    },
});

class DeleteAccount extends React.Component<any, any> {
    static contextType = GlobalStoreContext;
    context!: React.ContextType<typeof GlobalStoreContext>;
    state = {
        userInfo: {
            email: null,
            fullname: null,
            message: null,
            id: null,
        },
        submission: false,
        loading: false,
        confirm: false,
        showSupportModal: false,
    };

    onFinish = (e) => {
        e.preventDefault();
        const url = "/me/support";
        const userInfo = {
            email: this.state.userInfo.email ? this.state.userInfo.email : "",
            fullname: this.state.userInfo.fullname ? this.state.userInfo.fullname : this.context.userInfo.fullname,
            subject: "Delete Account",
            message: this.state.userInfo.message,
            id: this.context.userInfo.id,
        };
        trackPromise(
            fetch(url, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userInfo),
            })
                .then((data) => {
                    if (data.ok) {
                        return data.json();
                    }
                    throw new Error("Network error.");
                })
                .then(() => {
                    notification.success({
                        message: "Account Deletion Request Submitted ",
                        description: "Our team will review your deletion request!",
                        duration: globalAppConfig.NOTIFICATION_DURATION,
                    });
                })
                .then(() => {
                    this.setState({ submission: true });
                })
                .catch((err) => {
                    notification.error({
                        message: "Failed to Submit Account Deletion Request Ticket",
                        description: "The support ticket could not be submitted: " + err,
                        duration: globalAppConfig.NOTIFICATION_DURATION,
                    });
                })
        );
    };

    componentDidMount() {}

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                {this.context.userInfo && (
                    <ThemeProvider theme={theme}>
                        <Container maxWidth="sm" style={{ background: "#ffffff", padding: "15px" }}>
                            <Box className="mt-8">
                                <Typography variant="h3" className="uppercase">
                                    Delete PLOT Account
                                </Typography>
                                {!this.state.submission ? (
                                    <React.Fragment>
                                        <Alert className="mt-6" variant="outlined" severity="warning">
                                            <AlertTitle className="">WARNING! DELETETION IS PERMANENT.</AlertTitle>
                                            <Typography variant="subtitle1" className="uppercase">
                                                This removes your access to any team and jobsite your have joined.
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                If you have any questions or doubt whether you should delete your
                                                account please contact our support team instead.
                                            </Typography>
                                            <FeedbackHelpModal
                                                showModal={this.state.showSupportModal}
                                                onClose={() => {
                                                    this.setState({ showSupportModal: false });
                                                }}
                                            />
                                        </Alert>
                                        <form className="mt-4" onSubmit={this.onFinish}>
                                            <FormControl>
                                                <TextField
                                                    className="mt-6"
                                                    id="fullname"
                                                    label="Full Name"
                                                    variant="standard"
                                                    required
                                                    onChange={(e) => {
                                                        this.setState({
                                                            userInfo: {
                                                                ...this.state.userInfo,
                                                                fullname: e.target.value,
                                                            },
                                                        });
                                                    }}
                                                    defaultValue={this.context.userInfo.fullname}
                                                />
                                                <TextField
                                                    id="email"
                                                    label="Email Address"
                                                    variant="standard"
                                                    required
                                                    onChange={(e) => {
                                                        this.setState({
                                                            userInfo: {
                                                                ...this.state.userInfo,
                                                                email: e.target.value,
                                                            },
                                                        });
                                                    }}
                                                    defaultValue={
                                                        this.context.userInfo &&
                                                        this.context.userInfo.emails.count > 0 &&
                                                        this.context.userInfo.emails[0].email
                                                            ? this.context.userInfo.emails[0].email
                                                            : ""
                                                    }
                                                />
                                                <TextField
                                                    multiline
                                                    id="reasonForDeletion"
                                                    label="Main reason for deleting your PLOT account"
                                                    variant="standard"
                                                    required
                                                    onChange={(e) => {
                                                        this.setState({
                                                            userInfo: {
                                                                ...this.state.userInfo,
                                                                message: e.target.value,
                                                            },
                                                        });
                                                    }}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            required
                                                            onChange={() => {
                                                                this.setState({ confirm: !this.state.confirm });
                                                            }}
                                                        />
                                                    }
                                                    label="I confirm that I understand that deleting my account is irreversible"
                                                />
                                                <Stack direction="row" className="justify-end mt-4" spacing={2}>
                                                    <Button type="submit" sx={{ color: "white" }} variant="contained">
                                                        Confirm
                                                    </Button>
                                                </Stack>

                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    label="Age"
                                    
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select> */}
                                            </FormControl>
                                        </form>
                                    </React.Fragment>
                                ) : (
                                    <Result
                                        status="success"
                                        title="Successfully Submitted Your Account Deletion Request"
                                        subTitle="PLOT Support will review your request. Once your account is deleted you will receive a confirmation email."
                                    />
                                )}
                            </Box>
                        </Container>
                    </ThemeProvider>
                )}
            </>
        );
    }
}

export default DeleteAccount;
