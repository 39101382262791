import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import GlobalStoreProvider from "../src/GlobalStoreProvider";
import DeleteAccount from "../src/components/DeleteAccount/deleteAccount";
import Login from "../src/components/Login/login";
import "./searchApp.css";

const LoginApp = () => {
    return (
        <GlobalStoreProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/delete_account" element={<DeleteView />} />
                </Routes>
            </Router>
        </GlobalStoreProvider>
    );
};

export default LoginApp;

function DeleteView() {
    return (
        <>
            <GlobalStoreProvider>
                <DeleteAccount />
            </GlobalStoreProvider>
        </>
    );
}
