import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { faCircleEnvelope, faCirclePhone } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, FormGroup, TextField } from "@mui/material";
import { notification } from "antd";
import moment from "moment";
import React, { useState } from "react";
import GlobalStoreContext from "../../GlobalStoreContext";
import Helper from "../../Helper";
import { globalAppConfig } from "../../config";
import PlotAPI from "../../plotAPI";
import PhoneInput from "./PhoneInput";

interface LoginFormProps {
    email: string | null;
    mobile: string | null;
    useEmail: boolean;
    useMobile: boolean;
    setUseEmail: (useEmail: boolean) => void;
    setUseMobile: (useMobile: boolean) => void;
    setEmail: (email: string | null) => void;
    setMobile: (mobile: string | null) => void;
    setTimestamp: (timestamp: string) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    setShowLogin: (showLogin: boolean) => void;
    resetLogin: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
    email,
    mobile,
    useEmail,
    useMobile,
    setUseEmail,
    setUseMobile,
    setEmail,
    setMobile,
    setTimestamp,
    loading,
    setLoading,
    setShowLogin,
}) => {
    const context = React.useContext(GlobalStoreContext);

    const [searchParams] = useState({
        return_url: null,
        team_id: null,
        project_token: null,
    });

    const sendAuthRequest = async (values) => {
        return PlotAPI.userOTPLogin(values).then((response) => {
            if (response.error) {
                notification.error({
                    message: response.error_description,
                    description: "Please check your entries and try again",
                    duration: globalAppConfig.NOTIFICATION_DURATION,
                });
                return;
            }

            if (response.login_redirect_url !== null) {
                // Redirect to /ominlogin
                window.location.href = `${Helper.baseUrlAltSubdomain("app")}/omnilogin?login_redirect_url=${
                    response.login_redirect_url
                }`;
                return;
            }

            if (response.email || response.phone_number) {
                setEmail(response.email);
                setMobile(response.phone_number);
                setTimestamp(response.timestamp);
            }
            setLoading(false);
            setShowLogin(false);
        });
    };

    const checkForm = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (mobile && !Helper.isPhoneNumber(Helper.formatPhoneNumber(mobile))) {
            return notification.error({
                message: "Invalid Phone Number",
                description: "Please check your entries and try again",
                duration: globalAppConfig.NOTIFICATION_DURATION,
            });
        }

        if (email && !Helper.isEmail(email)) {
            return notification.error({
                message: "Invalid Email Address",
                description: "Please check your entries and try again",
                duration: globalAppConfig.NOTIFICATION_DURATION,
            });
        }

        const values = {
            email: email || null,
            mobile: mobile || null,
            timestamp: moment().format("dddd, MMMM Do YYYY, h:mm:ss a Z"),
            return_url: searchParams.return_url || "",
        };
        setLoading(true);
        setTimestamp(values.timestamp);
        sendAuthRequest(values);
    };

    const handleMobileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMobile(e.target.value);

        if (e.target.value.length > 0) {
            setUseEmail(false);
            setUseMobile(true);
        } else {
            setUseEmail(false);
            setUseMobile(false);
        }
    };

    const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 0) {
            setUseEmail(true);
            setUseMobile(false);
        } else {
            setUseEmail(false);
            setUseMobile(false);
        }
        setEmail(e.target.value);
    };

    return (
        <form id="loginForm" onSubmit={checkForm}>
            <FormGroup>
                <h2 className="font-plot-oswald text-plot-light-grey text-3xl uppercase">Login</h2>
                {context.isMobile && <span>Jobsite Management Made Easy</span>}
                <div
                    className={Helper.classNames(
                        "flex flex-row items-baseline w-full transition-all",
                        useEmail && "opacity-0"
                    )}
                >
                    <FontAwesomeIcon
                        icon={faCirclePhone}
                        size={"lg"}
                        className="mr-2"
                        color={useMobile ? "#ff9e00" : "#bdc1c6"}
                    />
                    <TextField
                        autoComplete="tel"
                        onFocusCapture={() => {
                            setUseEmail(false);
                            setUseMobile(true);
                        }}
                        onBlur={() => {
                            if (!mobile) {
                                setUseEmail(false);
                            }

                            setUseMobile(false);
                        }}
                        fullWidth
                        margin="normal"
                        id="mobile-number"
                        label="Mobile Number"
                        variant="standard"
                        InputProps={{
                            typeof: "tel",
                            inputComponent: PhoneInput as any,
                            type: "text",
                        }}
                        onChange={handleMobileInput}
                        value={mobile}
                        disabled={useEmail}
                        required={useMobile}
                    />
                </div>
            </FormGroup>
            <FormGroup>
                <div
                    className={Helper.classNames(
                        "flex flex-row items-baseline w-full transition-all",
                        useMobile && "opacity-0"
                    )}
                >
                    <FontAwesomeIcon
                        icon={faCircleEnvelope}
                        size={"lg"}
                        className="mr-2"
                        color={useEmail ? "#ff9e00" : "#bdc1c6"}
                    />
                    <TextField
                        autoComplete="email"
                        onFocusCapture={() => {
                            setUseEmail(true);
                            setUseMobile(false);
                        }}
                        onBlur={() => {
                            if (!email) {
                                setUseEmail(false);
                            }

                            setUseMobile(false);
                        }}
                        fullWidth
                        margin="normal"
                        id="email-address"
                        label="Email Address"
                        variant="standard"
                        inputProps={{
                            typeof: "email",
                        }}
                        onChange={handleEmailInput}
                        value={email}
                        disabled={useMobile}
                        required={useEmail}
                    />
                </div>
            </FormGroup>
            <button disabled={loading} className="plot-button primary-button text-center ml-6 px-8" type="submit">
                Login <FontAwesomeIcon icon={faRightToBracket} className="ml-2" />
            </button>
            <div className="text-center font-plot-open-sans text-sm mt-4">
                <p>
                    By clicking "Next", you agree to have at least one text or email message sent to you for
                    verification purposes. Standard message and data rates apply.
                </p>
                <Divider className="mb-2 text-plot-orange text-xl">-</Divider>
                <p>
                    We promise not to spam you or sell your information. By logging in or signing up, you agree to our{" "}
                    <a
                        href="https://www.getplot.com/terms-of-service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-plot-orange underline"
                    >
                        terms of service
                    </a>{" "}
                    and{" "}
                    <a
                        href="https://www.getplot.com/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-plot-orange underline"
                    >
                        privacy policy.
                    </a>
                </p>
            </div>
        </form>
    );
};

export default LoginForm;
