import { faCircleArrowLeft, faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Stack, TextField } from "@mui/material";
import React from "react";

interface LoginCodeProps {
    email: string | null;
    mobile: string | null;
    passcode: string;
    setPasscode: (passcode: string) => void;
    timestamp: string | null;
    onFinishPasscode: () => void;
    resetLogin: () => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
}

const LoginCode: React.FC<LoginCodeProps> = ({
    email,
    mobile,
    passcode,
    setPasscode,
    timestamp,
    onFinishPasscode,
    resetLogin,
    loading,
    setLoading,
}) => {
    const passcodeValue = (e) => {
        setPasscode(e.target.value);
    };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                setLoading(true);
                onFinishPasscode();
            }}
        >
            <Stack className="my-2 text-base" spacing={2}>
                {email ? (
                    <div>
                        If an account exists for <span className="text-plot-orange">{email}</span> an email will be sent
                        with a <span className="text-plot-orange">code</span> to use to log in
                    </div>
                ) : (
                    <div>
                        If an account exists for {mobile} a text will be sent with a
                        <span className="text-plot-orange">code</span> to use to log in with.
                    </div>
                )}
            </Stack>
            <div className="text-4xl font-plot-oswald uppercase font-medium text-plot-orange break-words">
                Enter the code below
            </div>
            {email && (
                <div className="text-sm text-plot-light-grey my-2 flex flex-col">
                    <div>Check for the following timestamp in the subject line:</div>
                    <div>{timestamp}</div>
                </div>
            )}

            <TextField
                fullWidth
                margin="normal"
                label="Passcode"
                variant="standard"
                inputProps={{
                    typeof: "email",
                }}
                onChange={(e) => passcodeValue(e)}
                value={passcode}
                required={true}
            />
            <Stack spacing={2} className="text-sm text-plot-light-grey my-2">
                {/* <div>
                    If you did not receive an {email ? "email" : "text"} {email && "please check your SPAM or "} verify
                    that you are logging in with the
                    {email ? " email address" : " phone number"} the invite was sent to.
                </div>
                <div>
                    If you are still having issues please double-check your invite and make sure you were invited via
                    {email ? " email" : " phone number"} at sign up.
                </div> */}

                {email ? (
                    <div>
                        IMPORTANT: If you do not receive an email, check your spam, and double-check this is the email
                        address that was used in your invite.
                    </div>
                ) : (
                    <div>
                        IMPORTANT: If you do not receive an text, double-check this is the phone number that was used in
                        your invite.
                    </div>
                )}
            </Stack>

            <div className="flex flex-row items-center ml-6 gap-4">
                <IconButton onClick={resetLogin}>
                    <FontAwesomeIcon icon={faCircleArrowLeft} />
                </IconButton>
                <button disabled={loading} className="plot-button primary-button text-center px-8" type="submit">
                    Login <FontAwesomeIcon icon={faRightToBracket} className="ml-2" />
                </button>
            </div>
        </form>
    );
};

export default LoginCode;
